<template>
  <div>
    <div>
      <div>
        <div class="my-container">
          <json-editor
            v-if="showEditor"
            mode="code"
            :content="content"
            @onChange="onChange"
            @disableUpdateButton="_ => disable_update_button = true"
            @enableUpdateButton="_ => disable_update_button = false"
          />

          <div class="d-flex justify-content-between align-items-center">
            <b-button class="mt-2" variant="primary" size="lg" :disabled="disable_update_button" @click="createPolicy(content)">
              Create Policy
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BTabs, BTab, BCardText, BFormInput, BFormGroup, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { get } from 'lodash';
import { validatePolicy } from "@core/utils/validations/validators"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import JsonEditor from "./JSONEditor.vue";
import StatementEditor from "./StatementEditor.vue"
import PolicyStatementsEditor from "./PolicyStatementsEditor.vue";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    JsonEditor,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BButton,
    StatementEditor,
    PolicyStatementsEditor
  },
  data() {
    return {
      data: {},
      policy: {
        name: "",
        statements: [
          {
            actions: [],
            effect: "Allow",
            resource: "*"
          }
        ],
        version: "2022-05-31",
      },
      showEditor: true,
      readOnly: false,
      content: {
        name: '',
        version: '2022-05-31',
        statements: [
          {
            actions: [],
            resource: '*',
            effect: 'Allow'
          }
        ]
      },
      resources: [],
      disable_update_button: true
    }
  },
  created() {
    this.getResources()
  },
  methods: {
    onChange(update) {
      this.content = update
    },
    onStatementsChanged(statements) {
      this.policy.statements = [{
        actions: Array.from(statements),
        effect: "Allow",
        resource: "*"
      }];
    },
    async createPolicy(payload) {
      try {
        payload.version = "2022-05-31"
        validatePolicy(payload)

        await this.useJwt().adminService.createPolicy(payload);
        await this.$router.push({
          name: "admin-policies"
        }).catch(() => { console.log('Failed to navigate to admin-policies') });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'EditIcon',
            variant: 'success',
            text: `Admin policy created successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    async getResources() {
      try {
        const response = await this.useJwt().adminService.getResources();
        this.resources = response.data.data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    }
  }
}
</script>
<style scoped>
.statements-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;

  color: #000000;
}
</style>
